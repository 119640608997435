import Helmet from "react-helmet"
import React from "react"
import ThankYouPage from "src/components/modules/typ"



export default function ThankYou() {
  return(
    <section>
      <Helmet><title>Thank you for installing Freshy Homepage - freshysearch.com</title></Helmet>
      <ThankYouPage></ThankYouPage>

    </section>
  )
}
