import React from "react"
import JSONData from "src/../branding/site-metadata.json"
import Styles from "./css/typ.module.scss"
import {isChrome,isFirefox} from "../lpscripts/browserdetect"
import Footerlinks from "src/components/footerlinks/footer"


class Typ extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      isChrome: isChrome(),
      isFirefox: isFirefox(),
      display: 'block',
      pageData: props.data,
      data: JSONData
    }
    if(isChrome()){
      const browserData = Object.assign(this.state.data, this.state.data.chrome);
      this.setState({data: browserData});
    }
    if(isFirefox()){
      const browserData = Object.assign(this.state.data, this.state.data.firefox);
      this.setState({data: browserData});
    }
    if(this.state.pageData){
      this.state.pageData.then(data => {
        const instanceData = Object.assign(JSONData, data);
        this.setState({ data: instanceData});
        if(isChrome()){
          const browserData = Object.assign(this.state.data, this.state.data.chrome);
          this.setState({data: browserData});
        }
        if(isFirefox()){
          const browserData = Object.assign(this.state.data, this.state.data.firefox);
          this.setState({data: browserData});
        }
      });
    }
  }

  render() {
    return (
      <div className={Styles.typStyles}>
    <div className={Styles.typContainer}>

    <img src={JSONData.headerLogo} className={Styles.typLogo} />
    <h1 className={Styles.typHL}>Thank You for adding {this.state.data.headerTitle} !</h1>
    <img src="/assets/freshy-hp.png" className={Styles.typimg} />
    <Footerlinks></Footerlinks>
    </div>
    </div>
    )
  }
}

export default Typ
